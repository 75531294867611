import { Paper } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import * as React from "react";
import { SLIGHTLY_DARK_TRANSPARENT } from "../constants/ColorConstants";
import { Match } from "../models/Match";
import Title from "./Title";

interface Props {
  recentMatchData: Match[];
}

let cellStyle = { color: "white", fontSize: "1rem" };

export default function RecentMatches(props: Props) {
  const theme = useTheme();
  return (
    <React.Fragment>
      <Title>RECENT MATCHES</Title>
      <Paper
        sx={{
          p: 2,
          display: "flex",
          flexDirection: "column",
          height: 200,
          overflowY: "auto",
          backgroundColor: SLIGHTLY_DARK_TRANSPARENT,
        }}
      >
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell sx={cellStyle}>OPPONENT</TableCell>
              <TableCell sx={cellStyle}>WINNER</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.recentMatchData.map((row) => (
              <TableRow key={row.id}>
                <TableCell sx={cellStyle}>{row.teamOneName ? row.teamOneName : ""}</TableCell>
                <TableCell sx={cellStyle}>{row.teamTwoName ? row.teamTwoName : ""}</TableCell>
                <TableCell sx={cellStyle}>{row.winnerName ? row.winnerName : ""}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {/* <Link color="primary" href="#" onClick={preventDefault} sx={{ mt: 3 }}>
          See more
        </Link> */}
      </Paper>
    </React.Fragment>
  );
}
