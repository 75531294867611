//Third Party
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { VERY_DARK_TRANSPARENT } from "../constants/ColorConstants";
import { Match } from "../models/Match";
import Announcements from "./Annoucements";
import RecentMatches from "./RecentMatches";
//First Party
import UpcomingMatches from "./UpcomingMatches";

function DashboardContent(props: Props) {
  return (
    <Box
      component="main"
      sx={{
        overflow: "auto",
        height: "75vh"
      }}
    >
      <Container maxWidth="lg" sx={{ mt: 1, mb: 1 }}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <Paper
              sx={{
                p: 2,
                display: "flex",
                flexDirection: "column",
                height: 250,
                overflowY: "auto",
                backgroundColor: VERY_DARK_TRANSPARENT,
              }}
            >
              <RecentMatches recentMatchData={props.recentMatchData} />
            </Paper>
          </Grid>
          <Grid item xs={12} md={8}>
            <Paper
              sx={{
                p: 2,
                display: "flex",
                flexDirection: "column",
                height: 250,
                backgroundColor: VERY_DARK_TRANSPARENT,
              }}
            >
              <UpcomingMatches data={props.upcomingMatchData} />
            </Paper>
          </Grid>

          <Grid item xs={12} md={12}>
            <Paper
              sx={{
                p: 2,
                display: "flex",
                flexDirection: "column",
                height: 1000,
                backgroundColor: VERY_DARK_TRANSPARENT,
              }}
            >
              <Announcements/>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

interface Props {
  recentMatchData: Match[];
  upcomingMatchData: Match[];
}

export default function Dashboard(props: Props) {
  return <DashboardContent recentMatchData={props.recentMatchData} upcomingMatchData={props.upcomingMatchData} />;
}
