import * as React from "react";
import dayjs, { Dayjs } from "dayjs";
import { Autocomplete, Button, createTheme, Modal, TextField, Toolbar } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { StyledTab, StyledTabs } from "../dashboard/StyledTabs";
import { TabPanel } from "../dashboard/TabPanel";
import { Match } from "../models/Match";
import { User } from "../models/User";
import { Clan } from "../models/Clan";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "75%",
  bgcolor: "black",
  border: "2px solid #000",
  boxShadow: 24,
};

function tabIdProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const theme = createTheme();

export function CreateMatchModal(props: Props) {
  const { isOpen, onClose } = props;
  const [tabValue, setTabValue] = React.useState<number>(0);
  const [createMatchOpen, setCreateMatchOpen] = React.useState<boolean>(false);
  const [leftUser, setLeftUser] = React.useState<User | null>(null);
  const [rightUser, setRightUser] = React.useState<User | null>(null);
  const [leftClan, setLeftClan] = React.useState<Clan | null>(null);
  const [rightClan, setRightClan] = React.useState<Clan | null>(null);
  const [dateValue, setDateValue] = React.useState<Dayjs | null>(dayjs());

  const [usersAttempts, setUsersAttempts] = React.useState<number>(0);
  const [usersLoading, setUsersLoading] = React.useState<boolean>(false);
  const [usersLoaded, setUsersLoaded] = React.useState<boolean>(false);
  const [users, setUsers] = React.useState<User[]>([]);

  const [clansAttempts, setClansAttempts] = React.useState<number>(0);
  const [clansLoading, setClansLoading] = React.useState<boolean>(false);
  const [clansLoaded, setClansLoaded] = React.useState<boolean>(false);
  const [clans, setClans] = React.useState<Clan[]>([]);

  const handleChange = (newValue: Dayjs | null) => {
    setDateValue(newValue);
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const submit = () => {
    let match: Match = {
      matchType: determineMatchType(tabValue),
      teamOneId: determineTeamId(tabValue, true),
      teamOneName: determineTeamName(tabValue, true),
      teamTwoId: determineTeamId(tabValue, false),
      teamTwoName: determineTeamName(tabValue, false),
      toBeCompletedBy: dateValue?.format("YYYY-MM-DD"),
    };
    fetch("/matches/create", {
      method: "POST",
      body: JSON.stringify({ matches: [match] }),
      headers: { "Content-Type": "application/json" },
    })
      .then((response) => response.text())
      .then((result) => {
        // console.log("result: ", result);
        if (result === "Saved") {
          props.onClose();
        }
      });
  };

  function determineMatchType(tabValue: number): string {
    switch (tabValue) {
      case 0:
        return "1v1";
      case 1:
        return "2v2";
      case 2:
        return "3v3";
      case 3:
        return "4v4";
      case 4:
        return "5v5";
      default:
        return "INVALID";
    }
  }

  function determineTeamId(tabValue: number, isLeft: boolean): number | undefined {
    if (tabValue === 0) {
      return isLeft ? leftUser?.id : rightUser?.id;
    } else {
      return isLeft ? leftClan?.id : rightClan?.id;
    }
  }

  function determineTeamName(tabValue: number, isLeft: boolean): string | undefined {
    if (tabValue === 0) {
      return isLeft ? leftUser?.name : rightUser?.name;
    } else {
      return isLeft ? leftClan?.name : rightClan?.name;
    }
  }

  const clanSelection = () => {
    return (
      <Grid container spacing={3}>
        <Grid item xs={5}>
          <Autocomplete
            value={leftClan}
            onChange={(event: any, newValue: Clan | null) => {
              setLeftClan(newValue);
            }}
            disablePortal
            id="combo-box-demo"
            options={clans}
            getOptionLabel={(clan: Clan) => clan.name}
            isOptionEqualToValue={(option: any, value: any) => option.id === value.id}
            sx={{ width: 300 }}
            renderInput={(params) => <TextField {...params} label="Select Clan" />}
          />
        </Grid>
        <Grid item xs={2}>
          VS
        </Grid>
        <Grid item xs={5}>
          <Autocomplete
            value={rightClan}
            onChange={(event: any, newValue: Clan | null) => {
              setRightClan(newValue);
            }}
            disablePortal
            id="combo-box-demo"
            options={clans}
            getOptionLabel={(clan: Clan) => clan.name}
            isOptionEqualToValue={(option: any, value: any) => option.id === value.id}
            sx={{ width: 300 }}
            renderInput={(params) => <TextField {...params} label="Select Clan" />}
          />
        </Grid>
      </Grid>
    );
  };

  function fetchUsers() {
    let newUsersAttempts = usersAttempts + 1;
    if (usersLoaded === false && usersLoading === false && newUsersAttempts < 10) {
      setUsersLoading(true);
      setUsersAttempts(newUsersAttempts);
      fetch("/users/all")
        .then((res) => res.json())
        .then(
          (result) => {
            if (result && result.users) {
              setUsersLoaded(true);
              setUsersLoading(false);
              setUsers(result && result.users ? result.users : []);
            }
          },
          (error) => {
            setUsersLoaded(false);
            setUsersLoading(false);
            // this.setState({
            //   isLoaded: true,
            //   error
            // });
          }
        );
    }
  }

  function fetchClans() {
    let newClansAttempts = clansAttempts + 1;
    if (clansLoaded === false && clansLoading === false && newClansAttempts < 10) {
      setClansLoading(true);
      setClansAttempts(newClansAttempts);
      fetch("/clans/all")
        .then((res) => res.json())
        .then(
          (result) => {
            if (result && result.clans) {
              setClansLoaded(true);
              setClansLoading(false);
              setClans(result && result.clans ? result.clans : []);
            }
          },
          (error) => {
            setClansLoaded(false);
            setClansLoading(false);
            // this.setState({
            //   isLoaded: true,
            //   error
            // });
          }
        );
    }
  }

  fetchUsers();
  fetchClans();
  return (
    <Modal open={isOpen} onClose={onClose}>
      <Box sx={style}>
        <Toolbar sx={{ diplay: "flex", justifyContent: "center" }}>
          <Typography component="h1" color="white">
            CREATE MATCH
          </Typography>
        </Toolbar>
        <Box
          sx={{
            flexGrow: 1,
            bgcolor: "black",
            display: "flex",
          }}
        >
          <StyledTabs orientation="vertical" centered value={tabValue} onChange={handleTabChange}>
            <StyledTab label="1v1" {...tabIdProps(0)} />
            <StyledTab label="2v2" {...tabIdProps(1)} />
            <StyledTab label="3v3" {...tabIdProps(2)} />
            <StyledTab label="4v4" {...tabIdProps(3)} />
            <StyledTab label="5v5" {...tabIdProps(4)} />
            {/* <StyledTab label="6v6" {...tabIdProps(5)} />
            <StyledTab label="7v7" {...tabIdProps(6)} />
            <StyledTab label="8v8" {...tabIdProps(7)} /> */}
          </StyledTabs>
          <Box
            sx={{
              bgcolor: "lightgray",
              width: "100%",
            }}
          >
            <TabPanel value={tabValue} index={0}>
              <Grid container spacing={3}>
                <Grid item xs={5}>
                  <Autocomplete
                    value={leftUser}
                    onChange={(event: any, newValue: User | null) => {
                      setLeftUser(newValue);
                    }}
                    disablePortal
                    id="combo-box-demo"
                    options={users}
                    getOptionLabel={(user: User) => user.name}
                    isOptionEqualToValue={(option: any, value: any) => option.id === value.id}
                    sx={{ width: 300 }}
                    renderInput={(params) => <TextField {...params} label="Select User" />}
                  />
                </Grid>
                <Grid item xs={2}>
                  VS
                </Grid>
                <Grid item xs={5}>
                  <Autocomplete
                    value={rightUser}
                    onChange={(event: any, newValue: User | null) => {
                      setRightUser(newValue);
                    }}
                    disablePortal
                    id="combo-box-demo"
                    options={users}
                    getOptionLabel={(user: User) => user.name}
                    isOptionEqualToValue={(option: any, value: any) => option.id === value.id}
                    sx={{ width: 300 }}
                    renderInput={(params) => <TextField {...params} label="Select User" />}
                  />
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
              {clanSelection()}
            </TabPanel>
            <TabPanel value={tabValue} index={2}>
              {clanSelection()}
            </TabPanel>
            <TabPanel value={tabValue} index={3}>
              {clanSelection()}
            </TabPanel>
            <TabPanel value={tabValue} index={4}>
              {clanSelection()}
            </TabPanel>
            {/* <TabPanel value={tabValue} index={5}>
              6s
            </TabPanel>
            <TabPanel value={tabValue} index={6}>
              7s
            </TabPanel>
            <TabPanel value={tabValue} index={7}>
              8s
            </TabPanel> */}

            <Toolbar sx={{ diplay: "flex", justifyContent: "center" }}>
              <MobileDatePicker
                label="To Be Completed By"
                inputFormat="MM/DD/YYYY"
                value={dateValue}
                onChange={handleChange}
                renderInput={(params) => <TextField {...params} />}
              />
            </Toolbar>
          </Box>
        </Box>
        <Toolbar sx={{ diplay: "flex", justifyContent: "right" }}>
          <Button variant="text" onClick={onClose}>
            CANCEL
          </Button>
          <Button variant="contained" onClick={submit}>
            CREATE
          </Button>
        </Toolbar>
      </Box>
    </Modal>
  );
  // }
}
