//Third Party
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import AddIcon from "@mui/icons-material/Add";
import NotificationsIcon from "@mui/icons-material/Notifications";
import Badge from "@mui/material/Badge";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import IconButton from "@mui/material/IconButton";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import _filter from "lodash/filter";
import * as React from "react";
import { styled } from "@mui/material/styles";
import { CreateMatchModal } from "../modals/CreateMatchModal";
//First Party
import { LeaderboardEntry } from "../models/LeaderboardEntry";
import { Match } from "../models/Match";
import { AppBar } from "./AppBar";
import Dashboard from "./Dashboard";
import Leaderboard from "./Leaderboard";
import Stream from "./Stream";
import { StyledTab, StyledTabs } from "./StyledTabs";
import { TabPanel } from "./TabPanel";
import ToolbarLogo from "./ToolbarLogo";
import BackgroundImage from "../assets/Base_Background.png";
import { SLIGHTLY_DARK_TRANSPARENT, VERY_DARK_TRANSPARENT } from "../constants/ColorConstants";
import LoginLogo from "./LoginLogo";
import { Button, TextField } from "@mui/material";
import UnderConstruction from "./UnderConstruction";

const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "orange",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "orange",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "orange",
    },
    "&:hover fieldset": {
      borderColor: "orange",
    },
    "&.Mui-focused fieldset": {
      borderColor: "orange",
    },
  },
});

function tabIdProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const theme = createTheme();

function MainContent(props: Props) {
  const [username, setUsername] = React.useState<string | undefined>(undefined);
  const [password, setPassword] = React.useState<string | undefined>(undefined);
  const [isLoggedIn, setIsLoggedIn] = React.useState<boolean>(false);

  const [tabValue, setTabValue] = React.useState(0);
  const [createMatchOpen, setCreateMatchOpen] = React.useState(false);

  const [matchesAttempts, setMatchesAttempts] = React.useState<number>(0);
  const [matchesLoading, setMatchesLoading] = React.useState<boolean>(false);
  const [matchesLoaded, setMatchesLoaded] = React.useState<boolean>(false);
  const [completedMatches, setCompletedMatches] = React.useState<Match[]>([]);
  const [upcomingMatches, setUpcomingMatches] = React.useState<Match[]>([]);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const onCreateMatchClose = () => {
    setCreateMatchOpen(false);
    fetchMatchesImpl();
  };

  function fetchMatches() {
    let newMatchesAttempts = matchesAttempts + 1;
    if (matchesLoaded === false && matchesLoading === false && newMatchesAttempts < 10) {
      setMatchesAttempts(newMatchesAttempts);
      fetchMatchesImpl();
    }
  }

  function fetchMatchesImpl() {
    setMatchesLoading(true);
    fetch("/matches/all")
      .then((res) => res.json())
      .then(
        (result) => {
          if (result && result.matches) {
            console.log("result.matches", result.matches);
            setMatchesLoaded(true);
            setMatchesLoading(false);
            setCompletedMatches(
              _filter(result.matches, (m: Match) => {
                return m.winnerId !== null;
              })
            );
            setUpcomingMatches(
              _filter(result.matches, (m: Match) => {
                return m.winnerId === null;
              })
            );
          }
        },
        (error) => {
          setMatchesLoaded(false);
          setMatchesLoading(false);
          // this.setState({
          //   isLoaded: true,
          //   error
          // });
        }
      );
  }

  function login() {
    //ACTUALLY LOGIN

    console.log("username: ", username);
    console.log("password: ", password);

    if (
      username !== undefined &&
      username !== "" && //
      password !== undefined &&
      password !== ""
    ) {
      let url = "php/bl_Login.php";
      let formData = new FormData();
      formData.append("name", username);
      formData.append("password", password);
      formData.append("appAuth", "LEAGUE");

      fetch(url, { method: "POST", body: formData }).then((res) => {
        if (res !== null && res.status === 200) {
          res.json();
          setIsLoggedIn(true);
        }else{
          //TODO: ERROR HANDLING

        }
      });
    }
  }

  fetchMatches();
  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          height: "100%",
          display: "flex",
          backgroundImage: `url(${BackgroundImage})`,
          backgroundAttachment: "fixed",
        }}
      >
        {/* NOT Logged In, Sign In */}
        <Box
          hidden={isLoggedIn}
          sx={{
            width: "100%",
          }}
        >
          <LoginLogo />
          <Container maxWidth="xs">
            <Paper
              sx={{
                height: 325,
                backgroundColor: VERY_DARK_TRANSPARENT,
              }}
            >
              <Grid container>
                <Grid item xs={12}>
                  <CssTextField
                    sx={{
                      mt: "3rem",
                    }}
                    InputLabelProps={{
                      style: { color: "white" },
                    }}
                    InputProps={{
                      style: { color: "white" },
                    }}
                    id="outlined-basic"
                    label="Username"
                    variant="outlined"
                    onChange={(e) => {
                      setUsername(e.target.value);
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <CssTextField
                    sx={{
                      mt: "3rem",
                    }}
                    InputLabelProps={{
                      style: { color: "white" },
                    }}
                    InputProps={{
                      style: { color: "white" },
                    }}
                    id="outlined-basic"
                    label="Password"
                    type="password"
                    variant="outlined"
                    onChange={(e) => {
                      setPassword(e.target.value);
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    size="large"
                    variant="contained"
                    sx={{ mt: "3rem", color: "black", background: "linear-gradient(#FFB52E, #D18700)" }}
                    onClick={login}
                  >
                    LOGIN
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          </Container>
        </Box>

        {/* Logged In, Dashboard */}
        <CssBaseline />
        <AppBar sx={{ backgroundColor: "transparent" }} position="absolute" open={false}>
          <Box hidden={!isLoggedIn}>
            <Toolbar
              sx={{
                backgroundColor: VERY_DARK_TRANSPARENT,
                pr: "24px", // keep right padding when drawer closed
              }}
            >
              <ToolbarLogo />
              <IconButton size="large" color="inherit" onClick={() => setCreateMatchOpen(true)}>
                <AddIcon />
              </IconButton>
              {/* <IconButton size="large" color="inherit">
                <Badge badgeContent={4} color="error">
                  <NotificationsIcon />
                </Badge>
              </IconButton> */}
              <IconButton size="large" color="inherit">
                <AccountCircleIcon />
              </IconButton>
            </Toolbar>
          </Box>
        </AppBar>

        <Box
          hidden={!isLoggedIn}
          sx={{
            width: "100%",
            paddingTop: "6.5rem",
          }}
        >
          <Box sx={{ backgroundColor: SLIGHTLY_DARK_TRANSPARENT, borderBottom: 1, borderColor: "divider" }}>
            <StyledTabs centered value={tabValue} onChange={handleTabChange}>
              <StyledTab label="DASHBOARD" {...tabIdProps(0)} />
              <StyledTab label="LEADERBOARDS" {...tabIdProps(1)} />
              <StyledTab label="CURRENT STREAMS" {...tabIdProps(2)} />
            </StyledTabs>
          </Box>
          <TabPanel value={tabValue} index={0}>
            <Dashboard recentMatchData={completedMatches} upcomingMatchData={upcomingMatches} />
          </TabPanel>
          <TabPanel value={tabValue} index={1}>
            <UnderConstruction />
            {/* <Leaderboard leaderboardData={props.leaderboardData} /> */}
          </TabPanel>
          <TabPanel value={tabValue} index={2}>
            <UnderConstruction />
            {/* <Stream /> */}
          </TabPanel>
        </Box>
        <CreateMatchModal isOpen={createMatchOpen} onClose={onCreateMatchClose} />
      </Box>
    </ThemeProvider>
  );
}

interface Props {
  leaderboardData: LeaderboardEntry[];
}

export default function Main(props: Props) {
  return <MainContent leaderboardData={props.leaderboardData} />;
}
