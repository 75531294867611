import { Paper } from "@mui/material";
import * as React from "react";
import { WHITE_TRANSPARENT } from "../constants/ColorConstants";
import Title from "./Title";
import { TwitterTimelineEmbed } from "react-twitter-embed";

interface Props {}

export default function Announcements(props: Props) {
  return (
    <React.Fragment>
      <Title>ANNOUNCEMENTS</Title>
      <Paper
        sx={{
          p: 2,
          display: "flex",
          flexDirection: "column",
          height: 900,
          overflowY: "auto",
          backgroundColor: WHITE_TRANSPARENT,
        }}
      >
        <TwitterTimelineEmbed
          // onLoad={function noRefCheck(){}}
          options={{
            height: 850,
          }}
          screenName="ZeroOpsGame"
          sourceType="profile"
        />
      </Paper>
    </React.Fragment>
  );
}
