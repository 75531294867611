//Third Party
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { VERY_DARK_TRANSPARENT } from "../constants/ColorConstants";
import Title from "./Title";

function UnderConstructionContent(props: Props) {
  return (
    <Box
      component="main"
      sx={{
        overflow: "auto",
        height: "75vh",
      }}
    >
      <Container sx={{ mt: 1, mb: 1 }}>
        <Paper
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "column",
            height: 100,
            backgroundColor: VERY_DARK_TRANSPARENT,
          }}
        >
          <Title>UNDER CONSTRUCTION</Title>
        </Paper>
      </Container>
    </Box>
  );
}

interface Props {}

export default function UnderConstruction(props: Props) {
  return <UnderConstructionContent />;
}
