import { LocalizationProvider } from "@mui/x-date-pickers";
// or for Day.js
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import React from "react";
// import logo from './logo.svg';
import "./App.css";
import Main from "./dashboard/Main";
import { LeaderboardEntry } from "./models/LeaderboardEntry";

// Generate Order Data
function createLeaderboardData(name: string, xp: number): LeaderboardEntry {
  return {
    id: Math.random().toString(),
    name: name,
    xp: xp,
  };
}

const leaderboardData: LeaderboardEntry[] = [
  createLeaderboardData("N64", 9808),
  createLeaderboardData("X", 8768),
  createLeaderboardData("DoX", 7653),
  createLeaderboardData("die", 6541),
  createLeaderboardData("eXe", 4234),
  createLeaderboardData("NWA", 3211),
];

function App() {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div className="App">
        <Main
          leaderboardData={leaderboardData}
        />
      </div>
    </LocalizationProvider>
  );
}

export default App;
