import * as React from "react";
import dayjs from "dayjs";
import Link from "@mui/material/Link";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Title from "./Title";
import { Match } from "../models/Match";
import { Paper } from "@mui/material";
import { SLIGHTLY_DARK_TRANSPARENT } from "../constants/ColorConstants";

interface Props {
  data: Match[];
}

let cellStyle = { color: "white", fontSize: "1rem" };

export default function UpcomingMatches(props: Props) {
  return (
    <React.Fragment>
      <Title>UPCOMING MATCHES</Title>
      <Paper
        sx={{
          p: 2,
          display: "flex",
          flexDirection: "column",
          height: 200,
          overflowY: "auto",
          backgroundColor: SLIGHTLY_DARK_TRANSPARENT,
        }}
      >
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell sx={cellStyle}>TYPE</TableCell>
              <TableCell sx={cellStyle}>YOUR TEAM</TableCell>
              <TableCell sx={cellStyle}>OPPONENT</TableCell>
              <TableCell sx={cellStyle} align="right">
                DATE
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.data.map((row) => (
              <TableRow key={row.id}>
                <TableCell sx={cellStyle}>{row.matchType ? row.matchType : ""}</TableCell>
                <TableCell sx={cellStyle}>{row.teamOneName ? row.teamOneName : ""}</TableCell>
                <TableCell sx={cellStyle}>{row.teamTwoName ? row.teamTwoName : ""}</TableCell>
                <TableCell sx={cellStyle} align="right">
                  {row.toBeCompletedBy ? dayjs(row.toBeCompletedBy).format("YYYY-MM-DD") : ""}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    </React.Fragment>
  );
}
