import * as React from "react";
import { Avatar } from "@mui/material";
import logo from "../zeroopslogo.png";

export default function LoginLogo() {
  return (
    <Avatar
      src={logo}
      alt="Zero Ops"
      variant="square"
      sx={{
        float: "none",
        marginTop: "2rem",
        marginBottom: "2rem",
        marginLeft: "auto",
        marginRight: "auto",
        height: "10rem",
        width: "15rem",
      }}
    />
  );
}
